/*
 *  * |///////////////////////////////////////////////////////////////////////|
 *  * |                                                                       |
 *  * | Copyright (C) STELLABLUE INTERACTIVE SERVICES PVT. LTD.               |
 *  * | All Rights Reserved                                                   |
 *  * |                                                                       |
 *  * | This document is the sole property of StellaBlue Interactive          |
 *  * | Services Pvt. Ltd.                                                    |
 *  * | No part of this document may be reproduced in any form or             |
 *  * | by any means - electronic, mechanical, photocopying, recording        |
 *  * | or otherwise - without the prior written permission of                |
 *  * | StellaBlue Interactive Services Pvt. Ltd.                             |
 *  * |                                                                       |
 *  * |///////////////////////////////////////////////////////////////////////|
 *  */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { GoBackGuardService } from './core/guards/go-back-guard.service';
import { ConfirmationPageComponent } from './shared/component/confirmation-page/confirmation-page.component';
//import { VideoChatSinglePageAppComponent } from './core/components/video-chat-single-page-app/video-chat-single-page-app.component';


const routes: Routes = [
    { path: '', redirectTo: 'auth/landing', pathMatch: 'full' },
    {
        path: 'auth',
        // canActivate:[AuthGuardService],
        loadChildren: () => import('src/app/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'privacy',
        loadChildren: () => import('src/app/privacy/privacy.module').then(m => m.PrivacyModule)
    },
    {
        path: 'cookies',
        loadChildren: () => import('src/app/cookie/cookie.module').then(m => m.CookieModule)
    },
    {
        path: 'terms',
        loadChildren: () => import('src/app/terms/terms.module').then(m => m.TermsModule)
    },
    {
        path: 'refund',
        loadChildren: () => import('src/app/refund-and-cancellation/refund-and-cancellation.module').then(m => m.RefundModule)
    },
    {
        path: 'about-us',
        loadChildren: () => import('src/app/about-us/about-us.module').then(m => m.AboutusModule)
    },    
    {
        path: 'checkout-flow',
        loadChildren: () => import('src/app/checkout-flow/checkout-flow.module').then(m => m.CheckoutFlowModule)
    },
    {
        path: 'about-medepal',
        loadChildren: () => import('src/app/about-medepal/about-medepal.module').then(m => m.AboutMedepalModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('src/app/contact-us/contact-us.module').then(m => m.ContactUsModule)
    },

    {
        path: 'individual',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        loadChildren: () => import('src/app/modules/individual/individual.module').then(m => m.IndividualModule)
    },
    {
        path: 'dashboard',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('src/app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'search',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        loadChildren: () => import('src/app/modules/search/search.module').then(m => m.SearchModule)
    },
    {
        path: 'doctor',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('src/app/modules/doctor/doctor.module').then(m => m.DoctorModule)
        //component : DoctorChamberComponent
    },
    {
        path: 'payment',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('src/app/modules/payment/payment.module').then(m => m.PaymentModule)
    },
    {
        path: 'appoinment',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('src/app/modules/appoinment/appoinment.module').then(m => m.AppoinmentModule)
    },
    {
        path: 'pharmacy',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('src/app/modules/pharmacy/pharmacy.module').then(m => m.PharmaModule)
    },
    {
        path: 'opd',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/service-provider/service-provider.module').then(m => m.ServiceProviderModule)
    },
    {
        path: 'pharmacy',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/service-provider/service-provider.module').then(m => m.ServiceProviderModule)
    },
    {
        path: 'searchPatient',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/fetch-patient/fetch-patient.module').then(m => m.FetchPatientModule)
    },
    {//issue number 765 -- [A procedure submenu has been added which looks like search patient by doctor page]
        path: 'searchProcedureByDoctor',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/search-procedure/search-procedure.module').then(m => m.SearchProcedureModule)
    },//end of issue number
    {
        path: 'sysadmin',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/sysadmin/sysadmin.module').then(m => m.SysadminModule)
    },
    {
        path: 'pharmacyRequisition',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/pharmacy-requisition/pharmacy-requisition.module').then(m => m.PharmacyRequisitionModule)
    },
    {
        path: 'searchPatientByDoctor',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/search-patient/search-patient.module').then(m => m.SearchPatientModule)
    },
    {
        path: 'peerconsulting',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/peer-consulting/peer-consulting.module').then(m => m.PeerConsultingModule)
    },
    {
        path: 'delivery',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/delivery/delivery.module').then(m => m.DeliveryModule)
    },
    //sbis-poc/app/issues/603
    {
        path: 'query-framework',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/query-framework/query-framework.module').then(m => m.QueryFrameworkModule)
    },
    //Working on app/issues/990
    {
        path: 'billing',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule)
    },
    //Working on app/issues/990
    {
        path: 'change-password',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/change-password/change-password.module').then(m => m.ChangePasswordModule)
    },
    {
        path: 'confirmation/:confirmationFor',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        component: ConfirmationPageComponent
        // loadChildren: 'src/app/shared/component/confirmation-page/'
    },
    {
        path: 'confirmation',
        // canLoad: [AuthGuardService, GoBackGuardService],
        // canDeactivate: [GoBackGuardService],
        // canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/confirmation/confirmation.module').then(m => m.ConfirmationModule)
    },
    {
        path: 'custom-analytic-query',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/custom-analystic-query/custom-analystic-query.module').then(m => m.CustomAnalysticQueryModule)

    },
    {
        path: 'custom-form',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/custom-form/custom-form.module').then(m => m.CustomFormModule)
    },
    /*{
        path: 'video-consulting/:onlineConsultLink', //single page video chat app
        component: VideoChatSinglePageAppComponent
    },*/
    {
        path: 'payee-account-details',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/payee-account-details/payee-account-details.module').then(m => m.PayeeAccountDetailsModule)
    },
    {
        path: 'id-proof-configuration',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/id-proof-document-configuration/id-proof-document-configuration.module').then(m => m.IdProofDocumentConfigurationModule)

    },
    {
        path: 'view-package-details',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/view-package-details/view-package-details.module').then(m => m.ViewPackageDetailsModule)

    },
    {
        path: 'view-package-details-misc',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/view-package-details-misc/view-package-details-misc.module').then(m => m.ViewPackageDetailsMiscModule)

    },
    {
        path: 'opd/search-patient',
        canLoad: [AuthGuardService, GoBackGuardService],
        canDeactivate: [GoBackGuardService],
        canActivate: [AuthGuardService],
        loadChildren: () => import('./modules/patient-search-by-role/patient-search-by-role.module').then(m => m.PatientSearchByRoleModule)

    },
    {
        path: 'NDHM',
         canLoad: [AuthGuardService, GoBackGuardService],
         canDeactivate: [GoBackGuardService],
         canActivate: [AuthGuardService],
        loadChildren: () => import('src/app/modules/ndhm/ndhm.module').then(m => m.NDHMModule)

    },
    {
        path: 'medical-record-share',
        loadChildren: () => import('./modules/medical-record-share/medical-record-share.module').then(m => m.MedicalRecordShareModule)
    },
    {
        path: 'ms/:medicalLink',
        loadChildren: () => import('./modules/medical-record-share/medical-record-share.module').then(m => m.MedicalRecordShareModule)
    },
    {
         path: ':entityIdentifier/:username',
        //component: UserComponent
        loadChildren: () => import('src/app/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'app-download-info',
        loadChildren: () => import('src/app/app-download-info/app-download-info.module').then(m => m.AppDownloadInfoModule)
    },
   
    { path: '**', redirectTo: 'auth/landing' }


];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
